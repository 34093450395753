// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.jsx"
// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import ReactParallax from "components/Parallax/Parallax.jsx"
import SEOHeader from "components/SEOHeader/SEOHeader.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SectionTimer from "../LandingPage/Sections/SectionTimer.jsx"
// page sections
import SectionBlogPosts from "./Sections/SectionBlogPosts.jsx"

const useStyles = makeStyles(blogPostsPageStyle)

export default function BlogPostsPage() {
  const { newsBackground } = useStaticQuery(graphql`
    query getBlogPostsPageImages {
      newsBackground: file(
        relativePath: { eq: "banners/chili-peppers-news.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div id="newsPage">
      <SEOHeader
        title="News - Chilimania"
        description="The latest news from Chilimania"
      />
      <CustomHeader />
      <ReactParallax
        imageData={newsBackground.childImageSharp.gatsbyImageData}
        imageTitle={"News Banner"}
        filter="dark"
        imageStyle={{ height: "75vh" }}
        small
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={12} className={classes.newsItem}>
              <h1 className={classes.pageTitle}>Chilimania News</h1>
            </GridItem>
          </GridContainer>
        </div>
      </ReactParallax>
      <div className={classes.main}>
        <SectionBlogPosts />
      </div>
      <SectionTimer />
      <CustomFooter />
    </div>
  )
}
